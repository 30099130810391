import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import { CompanyGdpr } from 'modules/companyPreview/CompanyGdpr';
import React from 'react';

const CompanyPrivacyPolicy: React.FC<PageProps> = () => {
  return (
    <Router basepath="/:lang/company-privacy-policy">
      <CompanyGdpr path=":companyHandle" />
    </Router>
  );
};

export default CompanyPrivacyPolicy;
