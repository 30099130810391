import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const header = css`
  padding: ${spacing(80)} 0;
  margin-bottom: ${spacing(80)};

  h1 {
    max-width: ${spacing(640)};
  }

  @media ${breakpoints.large} {
    padding: ${spacing(80)} ${spacing(112)};
  }
`;

export const title = css`
  text-transform: uppercase;
  margin-bottom: ${spacing(40)};
  ${font.size.tiny};
  color: hsl(var(--color-text-1));
`;

export const policy = css`
  max-width: ${spacing(528)};
  color: hsl(var(--color-grayscale-6));
  padding: ${spacing(24)} 0;
  border-top: 1px solid hsl(var(--color-borderLight-8));

  @media ${breakpoints.large} {
    margin: 0 ${spacing(112)};
  }
`;

export const wrapper = css`
  height: auto;
`;
