import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';
import { companyDataAction } from 'modules/profile/redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const useCompanyPreview = () => {
  const dispatch = useDispatch();

  const [gdpr, setGdpr] = useState<Gdpr>();
  const [isLoading, setIsLoading] = useState(false);

  const companyHttp = useHttpClient<Company>('/api/v1/company');

  async function getOpenings(
    handle: string,
    page = 1,
    openings?: JobOpeningListItem[],
  ) {
    dispatch(companyDataAction.loading());
    try {
      const { data } = await companyHttp.get<PublicOpeningsResponse>(
        `/${handle}/profile/openings`,
        {
          params: {
            page,
            perPage: 6,
          },
        },
      );
      dispatch(
        companyDataAction.publicOpenings({
          openings: openings ? [...openings, ...data.data] : data.data,
          page: data.page,
          lastPage: Math.ceil(data.total / data.perPage),
        }),
      );
    } catch (e) {}
  }

  async function getGdpr(handle: string) {
    setIsLoading(true);

    try {
      const { data } = await companyHttp.get<Gdpr>(
        `/${handle}/settings/gdpr-settings`,
      );
      setGdpr(data);
    } catch (e) {
      setGdpr(undefined);
    }

    setIsLoading(false);
  }

  return {
    gdpr,
    isLoading,
    getGdpr,
    getOpenings,
  };
};
