import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { companyDataAction } from 'modules/profile';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useGetPublicCompany = () => {
  const dispatch = useDispatch();

  const http = useHttpClient('api/v1/company');

  async function getPublicCompany(handle: string) {
    try {
      const { data } = await http.get<PublicCompany>(
        `${handle}/profile/public`,
      );
      dispatch(companyDataAction.publicCompany(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getPublicOpenings(handle: string) {
    try {
      const { data } = await http.get<PublicOpeningsResponse>(
        `${handle}/profile/openings`,
      );
      dispatch(
        companyDataAction.publicOpenings({
          openings: data.data,
          page: data.page,
          lastPage: Math.floor(data.total / data.perPage),
        }),
      );
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getPublicCompanyData(handle: string | undefined) {
    if (!handle) return;

    dispatch(companyDataAction.loading());

    const res = await Promise.all([
      getPublicCompany(handle),
      getPublicOpenings(handle),
    ]);
    const error = res.find((e) => e !== undefined);

    if (!error) return;

    dispatch(companyDataAction.error(error));
  }

  return {
    getPublicCompany: useCallback(getPublicCompanyData, []),
  };
};
