import { RouteComponentProps } from '@reach/router';
import {
  AppLayout,
  HeaderLayout,
  Heading,
  Loading,
  MainContent,
} from 'modules/app';
import React, { useEffect } from 'react';

import * as styles from 'modules/companyPreview/styles/CompanyGdpr.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { NotFound } from 'modules/notFound';
import { useCompanyPreview } from './hooks';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
const RichTextEditor = React.lazy(
  () => import('modules/app/components/textEditor/components/RichTextEditor'),
);

interface Props extends RouteComponentProps {
  companyHandle?: string;
}

export const CompanyGdpr: React.FC<Props> = ({ companyHandle }) => {
  const { getGdpr, gdpr, isLoading } = useCompanyPreview();

  useEffect(() => {
    if (!companyHandle) return;

    getGdpr(companyHandle);
  }, [companyHandle]);

  return (
    <AppLayout isPublic>
      <Loading
        isLoading={isLoading}
        customStyles={margin.top.xhuge}
        component={
          gdpr ? (
            <>
              <HeaderLayout>
                <div css={styles.header}>
                  <Heading as="h1" size="fluidHuge">
                    <FormattedMessage
                      id="companyPreview.gdpr.companyPolicy"
                      values={{ name: gdpr.legalName }}
                    />
                  </Heading>
                </div>
              </HeaderLayout>

              <MainContent customStyles={styles.wrapper}>
                <section css={styles.policy}>
                  <p css={styles.title}>
                    <FormattedMessage id="companyPreview.gdpr.privacyPolicy" />
                  </p>
                  <React.Suspense fallback={null}>
                    <RichTextEditor
                      isReadOnly={true}
                      value={gdpr?.privacyPolicy}
                    />
                  </React.Suspense>
                </section>
              </MainContent>
            </>
          ) : (
            <NotFound type="contentOnlyCenter" />
          )
        }
      />
    </AppLayout>
  );
};
